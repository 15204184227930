import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import Layout from '../../components/layout';
import AppStoreBadge from '../../images/svgs/app_store_badge.svg';
import dodgeGif from '../../images/flight-hazard/dodge.gif';
import wrapGif from '../../images/flight-hazard/wrap.gif';
import restGif from '../../images/flight-hazard/rest.gif';
import powerOrbGif from '../../images/flight-hazard/powerorbs.gif';
import powerupGif from '../../images/flight-hazard/powerup.gif';

const sectionSpacing = '45px';

const Hero = styled.div`
  text-align: center;

  @media screen and (min-width: 701px) {
    margin-bottom: ${sectionSpacing};
  }
`;

const Subtitle = styled.p`
  font-size: 20px;
  font-style: italic;
  font-weight: lighter;
  margin-bottom: 10px;
  color: #ffffffb3;
`;

const Brief = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: normal;
  margin-bottom: ${sectionSpacing};

  @media screen and (max-width: 700px) {
    align-items: center;
    flex-direction: column-reverse;
  }
`;

const GameGraphicContainer = styled.div`
  text-align: center;
  flex: 30%;
  max-width: 300px;
`;

const DescriptionContainer = styled.div`
  flex: 40%;

  @media screen and (min-width: 701px) {
    flex-direction: column-reverse;
    padding: 0 50px;
  }

  @media screen and (max-width: 700px) {
    margin-bottom: ${sectionSpacing};
  }
`;

const DescriptionDetails = styled.ul`
  margin-top: 18px;
  line-height: 24px;

  li {
    margin-bottom: 22px;
  }
`;

const TipsContainer = styled.div`
  margin-bottom: ${sectionSpacing};
`;

const TipsList = styled.ul`
  list-style: none;
  padding: 0;
  font-size: 1.3rem;
`;

const TipItem = styled.li`
  display: flex;
  text-align: left;
  /* flex-wrap: wrap; */
  align-items: center;
  margin-bottom: 30px;

  div {
    flex-shrink: 0;
  }

  img {
    border: 2px solid white;
    border-radius: 32px;
  }

  @media screen and (max-width: 700px) {
    flex-direction: column;
    text-align: center;
  }

  @media screen and (min-width: 701px) {
    p {
      padding-left: 36px;
    }
  }
`;

function useInterval(callback, delay) {
  const savedCallback = React.useRef(callback);

  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    if (delay === null) {
      return;
    }

    const id = setInterval(() => savedCallback.current(), delay);

    return () => clearInterval(id);
  }, [delay]);
}

const Slideshow = ({ cycleTime }) => {
  const [currentImage, setCurrentImage] = React.useState(0);
  const { allFile } = useStaticQuery(
    graphql`
      query {
        allFile(
          filter: { relativeDirectory: { eq: "flight-hazard/screenshots" } }
        ) {
          edges {
            node {
              childImageSharp {
                gatsbyImageData(width: 250)
              }
            }
          }
        }
      }
    `
  );

  useInterval(() => {
    const nextImage =
      currentImage < allFile.edges.length - 1 ? currentImage + 1 : 0;
    setCurrentImage(nextImage);
  }, cycleTime);

  return (
    <React.Fragment>
      <GatsbyImage
        image={getImage(allFile.edges[currentImage].node)}
        imgStyle={{ borderRadius: 5 }}
      />
    </React.Fragment>
  );
};

const FlightHazardLandingPage = () => {
  return (
    <React.Fragment>
      <Helmet>
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://longpoint.io/flight-hazard" />
        <meta property="og:title" content="Flight Hazard" />
        <meta
          property="og:image"
          content="https://longpoint.io/flight-hazard-icon.png"
        />
      </Helmet>
      <Layout maxWidth="55rem" title="Flight Hazard" rel="/flight-hazard">
        <Hero>
          <StaticImage
            src="../../images/flight-hazard/app-icon.png"
            alt="App icon"
            // style={{ border: 'grey 4px', borderRadius: 5 }}
            imgStyle={{ borderRadius: 5 }}
            width={150}
            height={150}
          />
          <h1>Flight Hazard</h1>
          <Subtitle>An "endless flier" mobile game</Subtitle>
        </Hero>
        <Brief>
          <GameGraphicContainer>
            <Slideshow cycleTime={5000} />
          </GameGraphicContainer>
          <DescriptionContainer>
            <p style={{ lineHeight: '34px' }}>
              Reach for the stars, <strong>but watch your head...</strong>Fly as
              high as you can while avoiding obstacles.{' '}
              <strong>Patience is key.</strong>
            </p>
            <DescriptionDetails>
              <li>An "Endless Runner" style game with flight physics</li>
              <li>
                Use force fields and invincibility power ups to get through
                tough spots
              </li>
              <li>
                Challenge friends to who can fly the highest and become the best
                pilot!
              </li>
            </DescriptionDetails>
            <div style={{ textAlign: 'center' }}>
              <OutboundLink
                href="https://apps.apple.com/us/app/flight-hazard/id1582705007"
                style={{ textAlign: 'center' }}
              >
                <AppStoreBadge style={{ width: 150 }} />
              </OutboundLink>
            </div>
          </DescriptionContainer>
        </Brief>
        <TipsContainer>
          <h1 style={{ textAlign: 'center' }}>Tips</h1>
          <TipsList>
            <TipItem>
              <img
                src={dodgeGif}
                alt="Avoid hitting platforms and lasers"
                width={200}
                height={200}
              />
              <p>
                Avoid hitting the bottom of platforms, and time when lasers are
                about to fire so you don't get zapped.
              </p>
            </TipItem>
            <TipItem>
              <img
                src={powerOrbGif}
                alt="Collect green power orbs to spawn power ups"
                width={200}
                height={200}
              />
              <p>
                Collect enough green power orbs to begin spawning power ups.
              </p>
            </TipItem>
            <TipItem>
              <img
                src={powerupGif}
                alt="Power ups spawn periodically"
                width={200}
                height={200}
              />
              <p>
                Power ups periodically spawn, so don't spend your power orbs if
                you don't need to.
              </p>
            </TipItem>
            <TipItem>
              <img
                src={wrapGif}
                alt="Fly off screen to come out the other side"
                width={200}
                height={200}
              />
              <p>
                When you fly off screen, you'll eventually come out the other
                side. Use this to your advantage!
              </p>
            </TipItem>
            <TipItem>
              <img
                src={restGif}
                alt="Land on top of platforms to rest"
                width={200}
                height={200}
              />
              <p>
                Need a break? Land on top of a platform to catch a breather.
              </p>
            </TipItem>
          </TipsList>
        </TipsContainer>
      </Layout>
    </React.Fragment>
  );
};

export default FlightHazardLandingPage;
